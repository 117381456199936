import { createJSTDate } from "./libs/date";
import { getAppEnv, isMockEnv } from "./libs/utils";

export const readResourceIds = [
  "50f423aa-8ea8-4385-81a1-a7d8da1c4939", // 氏名
  "5419c333-2863-4d57-9272-5f6c6c8989ec", // 住所
  "5ac941bf-88f3-47e3-b2e7-3bb0522aa14b", // 性別
  "612c6a3c-212a-402e-8c89-792a4b3e7889", // 生年月日
  "32df0061-de96-49a6-99d3-764497e64ed6", // 市区町村までの住所
];

export const cityAddressResourceId = "32df0061-de96-49a6-99d3-764497e64ed6";

export const MIYAGI_BOUSAI_RESOURCE_ID = import.meta.env
  .VITE_MIYAGI_BOUSAI_RESOURCE_ID as string;

export const MIYAGI_BOUSAI_SERVICE_ID = import.meta.env
  .VITE_MIYAGI_BOUSAI_SERVICE_ID as string;

export const MIYAGI_PPID_RESOURCE_ID = import.meta.env
  .VITE_MIYAGI_PPID_RESOURCE_ID as string;

export const MARUMORI_PPID_RESOURCE_ID = import.meta.env
  .VITE_MARUMORI_PPID_RESOURCE_ID as string;

export const DEMO_PPID_RESOURCE_ID = import.meta.env.VITE_DEMO_PPID_RESOURCE_ID;

export const TENANT_PPID_RESOURCE_ID_MAP = {
  miyagi: MIYAGI_PPID_RESOURCE_ID,
  marumori: MARUMORI_PPID_RESOURCE_ID,
  demo: DEMO_PPID_RESOURCE_ID,
};

// クッションミニアプリ系
export const CUSHION_SERVICE_ID = import.meta.env.VITE_CUSHION_SERVICE_ID;
// フォールバック用のテナントのサービスID
// 複数のテナントに対応しているQRコードだが、どのミニアプリもサブスクしていない場合、このミニアプリを開く
export const CUSHION_FALLBACK_SERVICE_ID = import.meta.env
  .VITE_MIYAGI_SERVICE_ID;

export const MIYAGI_INVITATION_FUND_SOURCE_ID =
  "0662db98-9fd2-4db3-931d-945bc002f076";

// 友達紹介キャンペーン終了日時
export const INVITATION_END_DATE = createJSTDate("2025-01-25 23:59:59");

// 一次抽選終了日時
export const PRIMARY_CAMPAIGN_END_DATE = createJSTDate("2025-01-25 23:59:59");

// 配布キャンペーン開始日時
export const ADDITIONAL_CAMPAIGN_START_DATE = createJSTDate(
  "2025-02-03 09:00:00",
);

// 配布キャンペーン終了日時
export const ADDITIONAL_CAMPAIGN_END_DATE = createJSTDate(
  "2025-02-20 23:59:59",
);

// ポイント利用期間終了日時
export const POINT_EXPIRATION_DATE = createJSTDate("2025-02-28 23:59:59");

export function getProfileResourceIds(tenantSlug: string) {
  const profileResourceIds =
    tenantSlug === "miyagi"
      ? [...readResourceIds, MIYAGI_BOUSAI_RESOURCE_ID]
      : readResourceIds;

  return profileResourceIds;
}

export function getSubscriptionIdResourceId(tenantSlug: string) {
  const mock = isMockEnv();
  const appEnv = getAppEnv();

  // prod/mockモック環境のResourceIDを分ける
  if (appEnv === "prod/mock" && mock) {
    if (tenantSlug === "miyagi") {
      return import.meta.env.VITE_MIYAGI_MOCK_PPID_RESOURCE_ID as string;
    }
    if (tenantSlug === "marumori") {
      return import.meta.env.VITE_MARUMORI_MOCK_PPID_RESOURCE_ID as string;
    }
  }

  if (tenantSlug === "demo") {
    return import.meta.env.VITE_DEMO_PPID_RESOURCE_ID as string;
  }
  if (tenantSlug === "miyagi") {
    return import.meta.env.VITE_MIYAGI_PPID_RESOURCE_ID as string;
  }
  if (tenantSlug === "marumori") {
    return import.meta.env.VITE_MARUMORI_PPID_RESOURCE_ID as string;
  }
  throw new Error("Invalid tenant slug");
}
