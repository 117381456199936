import type { UserBalance } from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { POINT_EXPIRATION_DATE } from "../config";
import type { TenantWithFeature } from "./connect";

export type MarumoriLotteryIneligibilityCardStatus = "hidden" | "show";

export function getMarumoriLotteryIneligibilityCardStatus({
  tenant,
  balances,
  currentTime,
}: {
  tenant: TenantWithFeature;
  balances: UserBalance[];
  currentTime: Date;
}): MarumoriLotteryIneligibilityCardStatus {
  if (tenant.slug !== "marumori") {
    return "hidden";
  }

  // ポイント発行履歴が存在する場合は表示しない
  // 一次抽選も二次抽選も対象外なため
  const hasBalance = balances.length > 0;

  if (hasBalance) {
    return "hidden";
  }

  if (POINT_EXPIRATION_DATE < currentTime) {
    return "hidden";
  }

  return "show";
}
