import { css } from "../../styled-system/css";

export function MarumoriLotteryIneligibilityCard() {
  return (
    <section
      className={css({
        bg: "surface.accentPrimaryLight",
        rounded: "xl",
        p: "16px",
        display: "flex",
        flexDir: "column",
        gap: "16px",
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "10px",
        })}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            gap: "6px",
          })}
        >
          応募期限の1月25日までに抽選に参加されていないため、ポイントの付与対象になりません
        </div>
      </div>
    </section>
  );
}
